import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import Hero from "../components/hero"
//import SlideshowSyncedWithSlider from "../components/slideshow-synced-with-slider"
import FeaturedArticle from "../components/featured-article"
import ArticleSlider from "../components/article-slider"
import CtaSlider from "../components/cta-slider"
import { Iframe } from "../components/iframe"
import ReactLightbox from "../components/react-lightbox"

const VolunteerPage = () => {

  const { strapiVolunteer, allStrapiArticle, allStrapiGallerySection } =
    useStaticQuery(graphql`
      query {
        strapiVolunteer {
          hero {
            ...StrapiHero
          }
          headings {
            title
            description
          }
          featuredArticle {
            ...StrapiFeaturedArticle
          }
          callToAction {
            ...StrapiCallToAction
          }
          slideShowSyncedWithSlider {
            ...SlideShowSyncedWithSliderSections
          }
          video {
            headings {
              title
              description
            }
            src
            title
            width
            height
          }
          seo {
            ...StrapiSeo
          }
        }
        allStrapiArticle(
          filter: {
            category: { name: { eq: "Volunteer" } }
            location: { eq: "volunteer-page" }
            featured: { eq: true }
          }
          sort: { order: DESC, fields: updatedAt }
        ) {
          nodes {
            ...StrapiArticles
          }
        }
        allStrapiGallerySection(
          filter: { location: { eq: "volunteer-page" } }
        ) {
          nodes {
            ...StrapiGallerySection
          }
        }
      }
    `)

  const {
    hero,
    seo,
    headings,
    featuredArticle,
    callToAction,
    video,
  } = strapiVolunteer

  const callToActionSections = callToAction.call_to_action_sections

  const articles = allStrapiArticle.nodes

  const gallerySection = allStrapiGallerySection.nodes[0]

  const structuredData = seo.structuredData

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "website",
            structuredData,
          })}
        </script>
      </Helmet>

      <Seo
        title={seo.metaTitle}
        description={seo.metaDescription}
        image={seo.metaImage.localFile.publicURL}
        post={false}
        metaSocial={seo.metaSocial}
        metaRobots={seo.metaRobots}
      />

      <Hero hero={hero} />

      <FeaturedArticle 
        featuredArticle={featuredArticle}
        title={headings.title} 
        description={headings.description}
        bg="uk-background-default"
      />

      {/* <div className="uk-container uk-padding uk-padding-remove-top">
          <SlideshowSyncedWithSlider sections={sections} />
        </div> */}

      <ReactLightbox
        images={gallerySection.images}
        location={gallerySection.location}
        title={gallerySection.headings.title}
        description={gallerySection.headings.description}
        bg="uk-background-muted"
        //remainingImages={remainingImages}
      />

      <Iframe video={video} />

      <ArticleSlider 
        articles={articles} 
        title="Featured Articles"
        description="Checkout some of our stories from save the street pooches"
        bg="uk-background-muted"
      />

      <CtaSlider 
        title={callToAction.title} 
        description={callToAction.description} 
        callToActionSections={callToActionSections} 
        bg="uk-background-default"
      />
    </>
  )
}

export default VolunteerPage
